import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const importAll = (r) => r.keys().map(r)
const images = importAll(require.context('../assets/certificates/', false, /\.(png|jpe?g)$/))

const Footer = styled.footer`
  padding: 0.5em 1.5em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  grid-template-areas: 'copyright contacts certificates';
  align-items: end;
  background: rgba(0, 0, 0, 0.58);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.58) 9%, rgba(0, 0, 0, 0) 100%);
  font-size: 0.8em;
  font-weight: 200;

  @media (max-width: 991px) {
    align-items: start;
    grid-gap: 0;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'contacts certificates certificates'
      'contacts copyright copyright';
  }
`

const Copyright = styled.div`
  grid-area: copyright;
  display: inline-block;

  @media (max-width: 991px) {
    text-align: right;
    align-self: end;
  }
`

const Certificates = styled.div`
  grid-area: certificates;
  text-align: right;
  height: 40px;

  img {
    margin: 0 5px;
    max-height: 40px;
  }
`

const Contacts = styled.div`
  grid-area: contacts;
  text-align: center;

  a {
    color: white;
    text-decoration: none;
    margin-right: 0.5em;

    &:hover {
      color: #a8cf44;
    }

    .icon {
      opacity: 0.6;
      margin-right: 0.7em;
    }

    .text {
      margin-right: 1.5em;
    }

    .title {
      display: none;
    }

    @media (max-width: 991px) {
      display: block;

      .title {
        display: inline-block;
      }
    }
  }

  @media (max-width: 991px) {
    text-align: left;
  }
`

const Contact = ({ icon, title, text = false, link, ...other }) => {
  let props = {}

  if (text) {
    props.title = text
  } else {
    props.title = title
    props.target = '_blank'
  }

  return (
    <a href={link} {...props}>
      <span className="icon">
        <FontAwesomeIcon icon={icon} {...other} />
      </span>
      {text ? <span className="text">{text}</span> : null}
      {title ? <span className="title">{title}</span> : null}
    </a>
  )
}

export default () => {
  return (
    <>
      <Footer>
        <Copyright>&copy; {new Date().getFullYear()} MB Partners Center</Copyright>
        <Contacts>
          <Contact icon="envelope" text="info@mbpc.lt" link="mailto:info@mbpc.lt" />
          <Contact icon="phone" rotation={90} text="+370 46 219792" link="tel:+37046219792" />
          <Contact
            icon={['fab', 'facebook-f']}
            title="Facebook"
            link="https://www.facebook.com/UAB-MB-Partners-Center-352889008246523/timeline/"
          />
          <Contact
            icon={['fab', 'linkedin']}
            title="Linkedin"
            link="https://www.linkedin.com/company/mbpc1/"
          />
        </Contacts>
        <Certificates>
          {images.map((image, index) => (
            <img src={image} key={index} alt="certificate" />
          ))}
        </Certificates>
      </Footer>
    </>
  )
}
