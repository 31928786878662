import React, { useState } from 'react'
import Navigation from './Navigation2'
import logo from '../assets/logo.png'
// import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { Handheld, Default } from './Responsive'
import HamburgerMenu from 'react-hamburger-menu'

const Logo = styled.div`
  display: inline-block;

  img {
    max-width: 175px;
    margin-top: 0.5em;
  }
`

const TopbarDefault = styled.div`
  display: flex;
  justify-content: space-between;
`

const TopbarHandheld = styled.div`
  position: relative;
  z-index: 1000;
`

const MenuButtonWrapper = styled.div`
  display: inline-block;
  float: right;
  top: 35px;
  right: 35px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`

const NavDefault = styled.div`
  display: inline-block;

  li {
    display: inline-block;
  }
`

const NavHandheld = styled.div`
  li {
    a {
      line-height: 80px;
      text-align: center;
      border-bottom: 1px solid #3a3d67;
      text-transform: uppercase;

      &:first-child {
        border-top: 1px solid #3a3d67;
      }

      &:hover,
      &.active {
        background: #222440;
      }
    }
  }
`

const MobileMenu = styled.div`
  background: #272a4d;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  overflow: hidden;
  padding-top: 130px;
  transition: all 100ms ease-out;
  height: 0vh;
  opacity: 0;

  li {
    a {
      line-height: 70px;
      transition: all 100ms;
    }
  }

  &.open {
    height: 100vh;
    opacity: 1;

    li {
      a {
        line-height: 80px;
      }
    }
  }
`

export default ({ setMenuIsOpen, api }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const logoComp = (
    <Logo onClick={() => api.moveTo('main')}>
      <a href="#main">
        <img src={logo} alt="logo" />
      </a>
    </Logo>
  )

  const toggle = (status) => {
    setMenuIsOpen(status)
    setMenuOpen(status)
  }

  return (
    <>
      <Default>
        <TopbarDefault>
          {logoComp}
          <NavDefault>
            <Navigation api={api} />
          </NavDefault>
        </TopbarDefault>
      </Default>
      <Handheld>
        <TopbarHandheld>
          {logoComp}
          <MenuButtonWrapper>
            <HamburgerMenu
              isOpen={isMenuOpen}
              menuClicked={() => toggle(!isMenuOpen)}
              width={24}
              height={16}
              strokeWidth={2}
              rotate={0}
              color="#fff"
              borderRadius={0}
              animationDuration={0.2}
            />
          </MenuButtonWrapper>
        </TopbarHandheld>
        <MobileMenu className={isMenuOpen ? 'open' : ''}>
          <NavHandheld>
            <Navigation callback={() => toggle(false)} />
          </NavHandheld>
        </MobileMenu>
      </Handheld>
    </>
  )
}
