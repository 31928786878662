import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import axios from 'axios'
import API from '../constants/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ProjectItemBox = styled.div`
  display: inline-block;
  position: relative;
  min-height: 210px;
  height: 25vh;

  a {
    height: 100%;
    display: block;
    width: 100%;
    color: white;
  }

  &.next-page,
  &.prev-page {
    .content {
      top: 67% !important;

      .location {
        padding-right: 25px;

        svg {
          height: 0.8em;
          color: #c9ee09;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`

const ProjectItem = styled.div`
  background-image: url(${(props) => props.image_url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 100%;
  width: 100%;

  &::after {
    content: '';
    background: rgba(73, 90, 121, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }

  .content {
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 15px 14px;
    right: 0;
    width: 80%;
    text-align: right;
    text-shadow: 0 0 1px black;
  }

  .location {
    display: block;
    font-weight: 400;
    font-size: 0.9em;
    position: absolute;
    right: 0;
    padding-right: 14px;
  }

  .title {
    position: relative;
    display: block;
    line-height: 1.2em;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 30px;

    &::after {
      content: ' ';
      display: block;
      background-color: #c9ee08;
      width: 30%;
      height: 4px;
      position: absolute;
      bottom: -20px;
      right: 0;
    }
  }

  &:hover {
    p {
      background: rgba(0, 0, 0, 0.7);
    }

    &::after {
      content: '';
      background: rgba(175, 203, 31, 0.92);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
    }
  }
`

const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto;
  grid-gap: 2px;

  @media (max-width: 991px) {
    grid-template-columns: auto auto;
  }

  @media (max-width: 567px) {
    grid-template-columns: auto;
  }
`

const ProjectListComp = ({ projects, prevPage, nextPage }) => {
  // Prev page
  let prevPageComp = null
  if (parseInt(projects.page) > 0) {
    prevPageComp = (
      <ProjectItemBox className="prev-page" key={'prev_page'} onClick={prevPage}>
        <ProjectItem>
          <div className="content">
            <span className="location">
              <FontAwesomeIcon icon="chevron-left" /> Previous page
            </span>
          </div>
        </ProjectItem>
      </ProjectItemBox>
    )
  }

  // Next page
  let nextPageComp = null
  if ((parseInt(projects.page) + 1) * parseInt(projects.per_page) < projects.total) {
    nextPageComp = (
      <ProjectItemBox className="next-page" key={'next_page'} onClick={nextPage}>
        <ProjectItem>
          <div className="content">
            <span className="location">
              Next page <FontAwesomeIcon icon="chevron-right" />
            </span>
          </div>
        </ProjectItem>
      </ProjectItemBox>
    )
  }

  return (
    <ProjectsList>
      {prevPageComp}
      {projects.data.map(({ slug, image, title, location }) => (
        <ProjectItemBox key={slug}>
          <Link to={`/projects${slug}`}>
            <ProjectItem image_url={image}>
              <div className="content">
                <span className="title">{title}</span>
                <span className="location">{location}</span>
              </div>
            </ProjectItem>
          </Link>
        </ProjectItemBox>
      ))}
      {nextPageComp}
    </ProjectsList>
  )
}

export default () => {
  const [loaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState({})
  const [page, setPage] = useState(0)

  useEffect(() => {
    if (!loaded && !loading) {
      setLoading(true)

      axios
        .get(API + 'projects/?per_page=6', { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          console.log(res.data)
          setProjects(res.data)
          setLoaded(true)
          setLoading(false)
        })
        .catch((err) => console.log(err))
    }
  })

  const goToPage = (newPage) => {
    setPage(newPage)
    setLoading(true)

    axios
      .get(API + `projects/?per_page=6&page=${newPage}`, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => {
        console.log(res.data)
        setProjects(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }

  return (
    <div>
      <PageTitle style={{ marginBottom: '1vh' }}>Projects</PageTitle>
      {loaded && !loading ? (
        <ProjectListComp
          projects={projects}
          nextPage={() => goToPage(page + 1)}
          prevPage={() => goToPage(page - 1)}
        />
      ) : (
        <div className="spinner" />
      )}
    </div>
  )
}
