import React from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Image = styled.div`
  background-image: url(${(props) => props.image});
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Overlay = styled.div`
  background-color: rgba(4, 7, 35, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export default () => {
  const BG = (image) => (
    <Background>
      <Image image={image} />
      <Overlay />
    </Background>
  )

  return (
    <Switch>
      <Route
        path={['/', '/new/']}
        exact
        render={() => BG('https://mbpc.lt/img_cache/bg/main.jpg')}
      />
      <Route path="/about/" exact render={() => BG('https://mbpc.lt/img_cache/bg/second.jpg')} />
      <Route path="/career/" exact render={() => BG('https://mbpc.lt/img_cache/bg/career.jpg')} />
      <Route render={() => null} />
    </Switch>
  )
}
