import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const MainContent = styled.div`
  text-align: center;
`

const Title = styled.h1`
  margin-top: ${({ lessSpacing }) => (lessSpacing ? '10vh' : '20vh')};
  text-transform: uppercase;
  font-size: 3.3em;

  @media (max-width: 991px) {
    font-size: 2.3em;
    margin-top: 10vh;
  }

  @media (min-width: 992px) {
    max-width: 80vw;
  }
`

const Main = ({ lessSpacing = false }) => {
  const phrases = ['Professionals for lasting cooperation', 'Industrial support']
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    autoplay: true,
    pauseOnHover: false,
  }

  return (
    <MainContent>
      <Slider {...settings}>
        {phrases.map((phrase, index) => (
          <Title lessSpacing={lessSpacing} key={index}>
            {phrase}
          </Title>
        ))}
      </Slider>
      <p>
        Working with high quality, big responsibility, concentrating on safety. <br /> We are aiming
        towards long-term cooperation with our partners.
      </p>
    </MainContent>
  )
}

export default Main
