import React, { useState } from 'react'
import styled from 'styled-components'
import Wrapper from './Wrapper'
import Topbar from './Topbar'
import Footer from './Footer'
import Background from './Background'
import { Route, Switch } from 'react-router-dom'

// import Main from '../pages/Main';
// import About from '../pages/About';
// import Projects from '../pages/Projects';
// import Services from '../pages/Services';
// import Career from '../pages/Career';
// import Contacts from '../pages/Contacts';

import Service from '../pages/Service'
import services from '../constants/services'

import Project from '../pages/Project'

const Header = styled.header`
  height: 125px;
`

export default () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <>
      <Background />
      <div>
        <Header className="upperLayer">
          <Wrapper header>
            <Topbar setMenuIsOpen={setMenuIsOpen} />
          </Wrapper>
        </Header>
        {menuIsOpen ? null : (
          <>
            <main className="upperLayer">
              <Wrapper>
                <Switch>
                  {services.map(({ slug, ...props }) => (
                    <Route
                      key={slug}
                      path={`/services/${slug}`}
                      exact
                      render={() => <Service {...props} />}
                    />
                  ))}
                  <Route path="/projects/:slug" exact component={Project} />
                  <Route render={() => null} />
                </Switch>
              </Wrapper>
            </main>
            <Footer />
          </>
        )}
      </div>
    </>
  )
}
