import React from 'react'
import styled from 'styled-components'
import PageTitle from '../components/PageTitle'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import img1 from '../assets/certificates/strongest3.jpg'
import img2 from '../assets/certificates/nen3.jpg'
import img3 from '../assets/certificates/iso4.jpg'
import img4 from '../assets/certificates/vca4.jpg'

const CertificateWrapper = styled.div`
  margin: 0 10px;

  img {
    max-width: 100%;
  }
`

const Description = styled.p``

const certs = [
  {
    image: img1,
    text: `<strong>"The Strongest in Lithuania 2017-2020"</strong> is the certificate confirming that the company and its management are reliable, fulfil and are very likely to fulfil their financial obligations in the future.`,
  },
  {
    image: img2,
    text: `<strong>NEN</strong> 4400-2 is a national standard that set requirements for temporary work businesses and contractors of work, including subcontractors, that have their registered office in the Netherlands with respect to the payment of taxes and social insurance contributions and the legitimacy of employment in the Netherlands.`,
  },
  {
    image: img3,
    text: `<strong>ISO 9001:2015</strong> is the Quality Management System standard valid for the following scope: Management and execution of industrial projects; Industrial rope access services.`,
  },
  {
    image: img4,
    text: `<strong>VCA</strong> is the Safety Management System certficate valid for: Maintenance and construction working at height with rope access and insdustrial mountaineering.`,
  },
]

const Certificate = ({ image, text = false }) => {
  return (
    <CertificateWrapper>
      <a href={image} target="_blank" rel="noopener noreferrer">
        <img src={image} alt="certificate" />
      </a>
      {text ? <Description dangerouslySetInnerHTML={{ __html: text }} /> : null}
    </CertificateWrapper>
  )
}

export default () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    draggable: true,
    autoplay: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <>
      <PageTitle>Certificates</PageTitle>
      <Slider {...settings}>
        {certs.map((item, index) => (
          <Certificate {...item} key={index} />
        ))}
      </Slider>
    </>
  )
}
