import React, { Component } from 'react'
import ReactMapGL, { NavigationControl, Marker } from 'react-map-gl'
import styled from 'styled-components'

const MapWrapper = styled.div`
  margin-top: 10vh;
  min-width: 35vw;

  @media (max-width: 567px) {
    min-width: 87vw;
  }
`

const MarkerImage = styled.div`
  background-image: url(https://mbpc.lt/img_cache/marker.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  height: 30px;
  width: 30px;
  opacity: 0.8;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`

const locations = [
  { lat: 56, lon: 24, title: 'Lithuania' }, // Lithuania
  { lat: -3, lon: -48, title: 'Brazilja' }, // Brazilja
  { lat: 32, lon: 116, title: 'Kinija' }, // Kinija
  { lat: 25.35, lon: 51.18, title: 'Qatar' }, // Qatar
  { lat: 10, lon: 8, title: 'Nigerija' }, // Nigerija
  { lat: 72, lon: -40, title: 'Grenlandija' }, // Grenlandija
  { lat: 65, lon: -18, title: 'Islandija' }, // Islandija
  { lat: 61, lon: 10, title: 'Norvegija' }, // Norvegija
  { lat: 62, lon: 15, title: 'Svedija' }, // Svedija
  { lat: 64, lon: 26, title: 'Suomija' }, // Suomija
  { lat: 59, lon: 26, title: 'Estija' }, // Estija
  { lat: 52, lon: 20, title: 'Lenkija' }, // Lenkija
  { lat: 56, lon: 10, title: 'Danija' }, // Danija
  { lat: 51, lon: 9, title: 'Vokietija' }, // Vokietija
  { lat: 46, lon: 2, title: 'Prancuzija' }, // Prancuzija
  { lat: 51, lon: 5, title: 'Belgija' }, // Belgija
  { lat: 52.3, lon: 5.45, title: 'Olandija' }, // Olandija
  { lat: 40, lon: -4, title: 'Ispanija' }, // Ispanija
  { lat: 39.3, lon: -8, title: 'Portugalija' }, // Portugalija
  { lat: 47, lon: 8, title: 'Sveicarija' }, // Sveicarija
  { lat: 46, lon: 25, title: 'Rumunija' }, // Rumunija
  { lat: 40.3, lon: 47.3, title: 'Azerbaidzanas' }, // Azerbaidzanas
  { lat: 45.12, lon: 10, title: 'Italija' }, // Italija
  { lat: 48.8, lon: 31.4, title: 'Ukraina' }, // Ukraina
  { lat: 51.5, lon: -0.2, title: 'Didžioji Britanija' }, // Didžioji Britanija
]

class Map extends Component {
  constructor(props) {
    super(props)

    const { viewport } = props

    this.map = null
    this.state = {
      viewport: {
        ...viewport,
        height: '90vh',
        width: '100%',
        zoom: 2,
      },
      isFull: false,
    }

    this.handleOnViewportChange = this.handleOnViewportChange.bind(this)
    this.resize = this.resize.bind(this)
  }

  // When component is mounted
  componentDidMount() {
    const newViewport = { ...this.state.viewport, ...this.props.viewport }
    // if new state is different from the old one, the update the state
    if (JSON.stringify(newViewport) !== JSON.stringify(this.state.viewport)) {
      /* istanbul ignore next */
      this.setState({ viewport: newViewport })
    }

    /* istanbul ignore next */
    if (this.map) {
      // Attach resize event listener
      window.addEventListener('resize', this.resize)
      // Trigger resize
      this.resize()
    }
  }

  // When component is being unmounted
  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  // handle Context Menu
  handleOnContextMenu(e) {
    e.preventDefault()
    e.stopImmediatePropagation()
  }

  // updates viewport state
  handleOnViewportChange(viewport) {
    if (this.props._handleViewportChange) this.props._handleViewportChange(viewport)
    this.setState({ viewport, contextMenu: { ...this.state.contextMenu, open: false } })
  }

  // Handles resizing
  resize() {
    const { viewport } = this.state

    // handle viewport changes
    this.handleOnViewportChange({ ...viewport, width: viewport.width, height: viewport.height })
  }

  render() {
    const { viewport } = this.state
    const eventListeners = { onViewportChange: this.handleOnViewportChange }

    return (
      <MapWrapper id="map">
        <ReactMapGL
          {...viewport}
          {...eventListeners}
          mapStyle="mapbox://styles/mapbox/light-v9"
          mapboxApiAccessToken={
            'pk.eyJ1IjoibWJwYyIsImEiOiJjanU5cDRzbjgwbHZkNDRwZDg2dHY0aHBxIn0.16Ha0I4RhxTM4frOfWUAIw'
          }
          doubleClickZoom={false}
          touchZoomRotate={false}
          scrollZoom={false}
          ref={(node) => {
            this.map = node
          }}
        >
          <div style={{ position: 'absolute', right: 0, margin: '10px' }}>
            <NavigationControl onViewportChange={this.handleOnViewportChange} showCompass={false} />
          </div>
          {locations.map(({ lat, lon, title }, i) => (
            <Marker key={i} latitude={lat} longitude={lon} offsetLeft={-20} offsetTop={-10}>
              <MarkerImage alt={title} />
            </Marker>
          ))}
        </ReactMapGL>
      </MapWrapper>
    )
  }
}

export default Map
