import React from 'react'
import Team from '../components/Team'

export default () => {
  return (
    <div>
      <Team />
    </div>
  )
}
