const root = 'https://mbpc.lt/img_cache/services/'
const img = (img) => root + img

export default [
  {
    slug: 'rope-access-technicians',
    title: 'Rope access technicians',
    subtext: [
      'Offshore and onshore works',
      'Construction, inspection, maintenance, repair of wind turbines, bridges, buildings. Various other rope access works',
    ],
    images: [
      img('rope_access_technicians/1.jpg'),
      img('rope_access_technicians/3.jpg'),
      img('rope_access_technicians/4.jpg'),
      img('rope_access_technicians/5.jpg'),
      img('rope_access_technicians/6.jpg'),
      img('rope_access_technicians/7.jpg'),
    ],
  },
  {
    slug: 'welders',
    title: 'Welders',
    subtext: [
      'Welding of black metal, stainless steel, aluminium',
      'Welding of pipeline and steel structures',
    ],
    images: [img('welders/1.jpg'), img('welders/2.jpg')],
  },
  {
    slug: 'painters-sandblasters',
    title: 'Painters-Sandblasters',
    subtext: [
      'Offshore and onshore projects',
      'Wind turbines',
      'Civil and industrial buildings',
      'Ship hull',
      'Metal constructions',
    ],
    images: [
      img('painters_blasters/1.jpg'),
      img('painters_blasters/2.jpg'),
      img('painters_blasters/3.jpg'),
      img('painters_blasters/4.jpg'),
      img('painters_blasters/5.jpg'),
      img('painters_blasters/6.jpg'),
    ],
  },
  {
    slug: 'insulators',
    title: 'Insulators (Armaflex, Rockwool, Platters)',
    subtext: ['Ship building industry', 'Various industry projects'],
    images: [
      img('insulators/1.jpg'),
      img('insulators/2.jpg'),
      img('insulators/3.jpg'),
      img('insulators/4.jpg'),
      img('insulators/5.jpg'),
      img('insulators/6.jpg'),
      img('insulators/7.jpg'),
    ],
  },
  {
    slug: 'air-duct-installers',
    title: 'Air duct installers',
    subtext: [],
    images: [
      img('air_duct_installers/1.jpg'),
      img('air_duct_installers/2.jpg'),
      img('air_duct_installers/3.jpg'),
      img('air_duct_installers/4.jpg'),
      img('air_duct_installers/5.jpg'),
    ],
  },
  {
    slug: 'constructions-workers',
    title: 'Construction workers',
    subtext: [],
    images: [
      img('construction_workers/1.jpg'),
      img('construction_workers/2.jpg'),
      img('construction_workers/3.jpg'),
      img('construction_workers/4.jpg'),
    ],
  },
  {
    slug: 'warehouse-workers',
    title: 'Warehouse workers',
    subtext: ['Maintenance, forklift, packaging line, production line workers.'],
    images: [img('warehouse_workers/1.png'), img('warehouse_workers/2.jpg')],
  },
  {
    slug: 'other-specialists',
    title: 'Other specialists',
    subtext: ['Other specialists can be discussed upon customer request.'],
    images: [img('other_specialists/1.jpg')],
  },
]
