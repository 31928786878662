import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import menu from '../constants/menu.json'

const Nav = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    a {
      color: white;
      display: block;
      text-decoration: none;
      // text-transform: uppercase;
      position: relative;
      line-height: 90px;
      padding: 0px 20px;
      outline: 0;
      border: 0;
      -webkit-tap-highlight-color: transparent;

      &.active,
      &:hover {
        color: #a8cf44;
      }
    }
  }
`

export default ({ callback = () => {} }) => (
  <Nav>
    {menu.map(({ path, title }) => (
      <li key={path} onClick={() => callback()}>
        <NavLink to={path.replace('/', '/#')}>{title}</NavLink>
      </li>
    ))}
  </Nav>
)
