import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css'
import Layout from './components/Layout'
import * as serviceWorker from './serviceWorker'
import './index.scss'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faEnvelope,
  faPhone,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import Fullpage from './pages/Fullpage'

library.add(faEnvelope)
library.add(faPhone)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faFacebookF)
library.add(faLinkedin)

ReactDOM.render(
  <Router>
    <Switch>
      <Route path={['/projects/:slug', '/services/:slug']} exact component={Layout} />
      <Route component={Fullpage} />
    </Switch>
  </Router>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
