import React, { useState } from 'react'
import styled from 'styled-components'
import Wrapper from '../components/Wrapper'
import Topbar from '../components/Topbar2'
import Footer from '../components/Footer'
import NewBackground from '../components/NewBackground'
// import { Route, Switch } from 'react-router-dom';

import Main from '../pages/Main'
import About from '../pages/About'
import Projects from '../pages/Projects'
import Services from '../pages/Services'
import Career from '../pages/Career'
import Contacts from '../pages/Contacts'

import ReactFullpage from '@fullpage/react-fullpage'

const fullpageOptions = {
  anchors: ['main', 'about', 'services', 'projects', 'career', 'contacts'],
  scrollOverflow: true,
  pluginWrapper: () => {
    require('fullpage.js/vendors/scrolloverflow')
    // require('./statics/fullpage.scrollHorizontally.min');
  },
}

const Header = styled.header`
  height: 125px;
  position: absolute !important;
  width: 100vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
`

export default () => {
  const [api, setApi] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const sections = (
    <ReactFullpage
      {...fullpageOptions}
      render={({ state, fullpageApi }) => {
        if (!api) setApi(fullpageApi)

        return (
          <ReactFullpage.Wrapper>
            <div className="section" data-anchor="main">
              <NewBackground image="https://mbpc.lt/img_cache/bg/main.jpg" />
              <Wrapper zIndex="5">
                <Main lessSpacing />
              </Wrapper>

              <span onClick={() => fullpageApi.moveSectionDown()} className="scroll-btn">
                <a href="#about">
                  <span className="mouse">
                    <span></span>
                  </span>
                </a>
                <p>scroll me</p>
              </span>
            </div>
            <div className="section" data-anchor="about">
              <NewBackground image="https://mbpc.lt/img_cache/bg/second.jpg" />
              <Wrapper zIndex="5">
                <br />
                <br />
                <br />
                <About />
                <br />
                <br />
              </Wrapper>
            </div>
            <div className="section" data-anchor="services">
              <Wrapper>
                <Services />
              </Wrapper>
            </div>
            <div className="section" data-anchor="projects">
              <Wrapper>
                <Projects />
              </Wrapper>
            </div>
            <div className="section" data-anchor="career">
              <NewBackground image="https://mbpc.lt/img_cache/bg/career.jpg" />
              <Wrapper zIndex="5">
                <br />
                <br />
                <br />
                <Career />
                <br />
                <br />
              </Wrapper>
            </div>
            <div className="section" data-anchor="contacts">
              <Wrapper>
                <br />
                <br />
                <br />
                <Contacts />
              </Wrapper>
              <br />
              <br />
              <Footer />
            </div>
          </ReactFullpage.Wrapper>
        )
      }}
    />
  )

  return (
    <>
      <Header className="upperLayer">
        <Wrapper>
          <Topbar api={api} setMenuIsOpen={setMenuIsOpen} />
        </Wrapper>
      </Header>
      {menuIsOpen ? null : sections}
    </>
  )
}
