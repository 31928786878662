import React from 'react'
import PageTitle from '../components/PageTitle'
import { Link } from 'react-router-dom'
import ContactForm from '../components/ContactForm'

export default () => {
  return (
    <div>
      <div style={{ marginBottom: '6vh' }}>
        <PageTitle style={{ marginBottom: '2vh' }}>Career</PageTitle>
        <p>
          Please fill out the form or{' '}
          <Link to="/contacts/" className="link">
            contacts us
          </Link>{' '}
          to get information about career opportunities.
        </p>
      </div>
      <ContactForm />
    </div>
  )
}
