import React from 'react'
import styled from 'styled-components'
import team from '../constants/team'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Image = styled.figure`
  ${(props) => (props.image_url ? `background-image: url(${props.image_url});` : '')}
`

const Member = ({ full_name, position, image = false, phonenumber, email }) => (
  <div className="member">
    <Image className="image" image_url={image} />
    <div className="details">
      <div className="name">{full_name}</div>
      <div className="position">{position}</div>
      <div className="phonenumber">
        <FontAwesomeIcon icon="phone" rotation={90} />{' '}
        <a href={`tel:${phonenumber}`}>{phonenumber}</a>
      </div>
      <div className="email">
        <FontAwesomeIcon icon="envelope" /> <a href={`tel:${email}`}>{email}</a>
      </div>
    </div>
  </div>
)

export default () => (
  <div>
    <h1>Team</h1>
    <div className="team">
      {team.map((data, index) => (
        <Member key={index} {...data} />
      ))}
    </div>
  </div>
)
