import React from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import Images from '../components/Images'
import styled from 'styled-components'

const ButtonBack = styled.button`
  border: none;
  background: #fefefe;
  border-radius: 16px;
  padding: 5px 21px;
  color: black;
  font-size: 0.85em;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const GoToProjects = styled.button`
  background: transparent;
  border: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1.5em;
  padding: 0.5em 1.5em;
  border-radius: 7px;
  margin: 2em auto 0;
  display: inline-block;
  text-decoration: none;

  &::after {
    margin-left: 20px;
    content: '>';
    color: #98d400;
  }

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.25);
  }
`

export default ({ title, subtext = [], images = [] }) => {
  return (
    <div>
      <Link to="/#services">
        <ButtonBack>{`< Go back`}</ButtonBack>
      </Link>
      <PageTitle>{title}</PageTitle>
      <ul className="colored-bullets">
        {subtext.map((text, index) => (
          <li key={index}>{text}</li>
        ))}
      </ul>
      <Images data={images} />
      <Link to="/#projects">
        <GoToProjects>Show Projects</GoToProjects>
      </Link>
    </div>
  )
}
