import React from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '../components/PageTitle'
import services from '../constants/services'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'

const SliderImg = styled.div`
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('${(props) => props.img}');
`

const ServicePageWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 40%;
  grid-gap: 20px;

  .ServicePageLeft {
  }

  .ServicePageRight {
    margin-top: 10vh;
    max-width: 90vw;

    img {
      max-width: 100%;
    }
  }

  @media (max-width: 991px) {
    grid-template-columns: auto;
  }
`

const service_images = [
  'https://mbpc.lt/img_cache/services/gallery/1.jpg',
  'https://mbpc.lt/img_cache/services/gallery/2.jpg',
  'https://mbpc.lt/img_cache/services/gallery/3.jpg',
  'https://mbpc.lt/img_cache/services/gallery/4.jpg',
  'https://mbpc.lt/img_cache/services/gallery/5.jpg',
  'https://mbpc.lt/img_cache/services/gallery/6.jpg',
  'https://mbpc.lt/img_cache/services/gallery/7.jpg',
  'https://mbpc.lt/img_cache/services/gallery/8.jpg',
  'https://mbpc.lt/img_cache/services/gallery/9.jpg',
  'https://mbpc.lt/img_cache/services/gallery/10.jpg',
  'https://mbpc.lt/img_cache/services/gallery/11.jpg',
]

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  draggable: true,
  autoplay: true,
  pauseOnHover: false,
}

export default () => {
  return (
    <ServicePageWrapper>
      <div className="ServicePageLeft">
        <PageTitle>Services</PageTitle>
        <p>
          Our <b>experienced team of professionals</b> includes:
        </p>
        <ul className="colored-arrows">
          {services.map(({ slug, title }, index) => (
            <li key={index}>
              <Link to={`/services/${slug}`}>{title}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="ServicePageRight">
        <Slider {...settings}>
          {service_images.map((item, index) => (
            <SliderImg img={item} key={index} styles={{ border: '1px' }} />
          ))}
        </Slider>
      </div>
    </ServicePageWrapper>
  )
}
