import React, { useState, useEffect } from 'react'
import PageTitle from '../components/PageTitle'
import styled from 'styled-components'
import axios from 'axios'
import API from '../constants/api'

const ButtonBack = styled.button`
  border: none;
  background: #fefefe;
  border-radius: 16px;
  padding: 5px 21px;
  color: black;
  font-size: 0.85em;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const Location = styled.p`
  display: block;
  position: relative;
  font-weight: 200;
  text-transform: initial;
  font-size: 0.5em;

  &::before {
    content: ' ';
    display: block;
    background-color: #c9ee08;
    width: 75px;
    height: 2px;
    position: absolute;
    top: -12px;
    left: 0;

    @media (max-width: 991px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const ImageGrid = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-gap: 15px;
  min-height: 300px;
  height: 50vh;

  @media (max-width: 991px) {
    height: 100vh;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
`

const Image = styled.figure`
    background-image: url(${(props) => props.image_url});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0;
    opacity: ${(props) => (props.main || props.active ? 1 : 0.5)}
    ${(props) => (props.active ? 'box-shadow: inset 0px 0 0 5px #afcb1f;' : '')}

    &:hover {
        cursor: ${(props) => (props.main ? 'auto' : 'pointer')};
        opacity: ${(props) => (props.main ? 1 : props.active ? 1 : 0.8)};
    }
`

const ImageList = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-gap: 5px;
  height: 100%;
`

export default ({ match, history }) => {
  const path = `/${match.params.slug}`

  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [project, setProject] = useState({})

  const [mainImage, setMainImage] = useState(0)

  useEffect(() => {
    if (!loaded && !loading) {
      axios
        .get(API + `projects/?path=${path}`, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          console.log(res.data)
          setProject(res.data)
          setLoaded(true)
          setLoading(false)
        })
        .catch((err) => console.log(err))
    }
  })

  let contentComp = <div className="spinner" />

  if (loaded && !loading) {
    contentComp = (
      <>
        <ButtonBack onClick={() => history.goBack()}>{`< Go back`}</ButtonBack>
        <PageTitle style={{ marginBottom: '0vh', marginTop: '4vh' }}>
          {project.title}
          <Location>{project.location}</Location>
        </PageTitle>
        <ImageGrid>
          <Image main={true} image_url={project.images[mainImage]} />
          <ImageList>
            {project.images.map((image, index) => (
              <Image
                active={index === mainImage}
                onClick={() => setMainImage(index)}
                image_url={image}
                key={index}
              />
            ))}
          </ImageList>
        </ImageGrid>
      </>
    )
  }

  return <div>{contentComp}</div>
}
