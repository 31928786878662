import React from 'react'
import styled from 'styled-components'

const Images = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10vh;

  figure {
    margin: 0;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 400px;
    flex-basis: calc(100% / 2 - 4px);
    margin: 2px;

    @media (max-width: 991px) {
      height: 300px;
      flex-basis: 100%;
    }
  }

  @media (max-width: 991px) {
    margin-top: 5vh;
  }
`

const Image = ({ url }) => {
  return <figure style={{ backgroundImage: `url(${url})` }} />
}

export default ({ data = [] }) => (
  <Images>
    {data.map((image, index) => (
      <Image url={image} key={index} />
    ))}
  </Images>
)
