import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import API from '../constants/api'

const ContactFormHolder = styled.div`
  @media (max-width: 991px) {
    grid-row: 1;
  }
`

const ContactForm = styled.div`
  max-width: 400px;
  padding: 20px;
  // background: rgba(0, 0, 0, 0.24);
  background: rgba(0, 0, 0, 0.5);
  min-width: 350px;
  border-radius: 2px;

  @media (max-width: 567px) {
    min-width: min-content;
  }
`
const InputField = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`
const Label = styled.span`
  display: block;
  font-weight: 200;
  font-size: 0.8em;
  margin-bottom: 5px;

  ${(props) =>
    props.required
      ? `&::after {
            content: "*";
            color: #a8cf44;
            margin-left: 4px;
        }`
      : ''}
`
const Input = styled.input`
  width: calc(100% - 14px);
  border-radius: 2px;
  border: ${(props) => (props.error ? '1px solid #f44336' : '1px solid transparent')};
  background: rgba(255, 255, 255, 0.2);
  line-height: 1em;
  padding: 4px 6px 2px;
  color: white;
`
const TextArea = styled.textarea`
  width: calc(100% - 14px);
  border-radius: 2px;
  border: ${(props) => (props.error ? '1px solid #f44336' : '1px solid transparent')};
  background: rgba(255, 255, 255, 0.2);
  line-height: 1em;
  padding: 4px 6px 2px;
  color: white;
  resize: none;
  height: 125px;
`

const SubmitButton = styled.button`
  border: 0;
  background: #a8cf44;
  width: 100%;
  line-height: 2.2em;
  color: white;
  border-radius: 2px;

  ${(props) =>
    props.sending
      ? `
            opacity: .5;
            background: #6b7356;
            outline: 0;
        `
      : `
            &:hover {
                cursor: pointer;
                background:#afce5f
            }

            &:focus, &:active {
                background: #8ea948;
            }
        `}
`

const Success = styled.p`
  text-align: center;
`

export default () => {
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)

  const [details, setDetails] = useState({
    name: '',
    email: '',
    phonenumber: '',
    message: '',
  })

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  })

  const updateDetails = (key, value) => {
    if (!sending) {
      setErrors({ ...errors, [key]: false })
      setDetails({ ...details, [key]: value })
    }
  }

  const onSubmit = () => {
    if (!sending) {
      const errorsState = {
        name: false,
        email: false,
        message: false,
      }

      if (details.name.length < 3) errorsState.name = true
      if (details.email.length < 3 || details.email.indexOf('@') === -1) errorsState.email = true
      if (details.message.length < 5) errorsState.message = true

      setErrors(errorsState)

      if (!(errorsState.name || errorsState.email || errorsState.message)) {
        setSending(true)
        axios
          .post(API + `mail/`, details, { headers: { 'Content-Type': 'application/json' } })
          .then((res) => {
            console.log(res)
            setSent(true)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
  if (sent) {
    return (
      <ContactFormHolder>
        <h1>Contact Form</h1>
        <ContactForm>
          <Success>
            Thank you! <br />
            Your message has been sent.
          </Success>
        </ContactForm>
      </ContactFormHolder>
    )
  } else
    return (
      <ContactFormHolder>
        {/* <h1>Contact Form</h1> */}
        <ContactForm>
          <InputField>
            <Label required>Name</Label>
            <Input
              error={errors.name}
              value={details.name}
              onChange={(e) => updateDetails('name', e.target.value)}
            />
          </InputField>
          <InputField>
            <Label required>Email</Label>
            <Input
              error={errors.email}
              type="email"
              value={details.email}
              onChange={(e) => updateDetails('email', e.target.value)}
            />
          </InputField>
          <InputField>
            <Label>Phone number</Label>
            <Input
              value={details.phonenumber}
              onChange={(e) => updateDetails('phonenumber', e.target.value)}
            />
          </InputField>
          <InputField>
            <Label required>Message</Label>
            <TextArea
              error={errors.message}
              value={details.message}
              onChange={(e) => updateDetails('message', e.target.value)}
            />
          </InputField>
          <SubmitButton sending={sending} onClick={onSubmit}>
            {sending ? 'Sending...' : 'Submit'}
          </SubmitButton>
        </ContactForm>
      </ContactFormHolder>
    )
}
