export default [
  {
    full_name: 'Mindaugas Bružas',
    position: 'Director',
    phonenumber: '+370 619 83644',
    email: 'mindaugas@mbpc.lt',
    image: require('../assets/team/v2/Mindaugas.png'),
  },
  {
    full_name: 'Sigita Bružienė',
    position: 'Deputy director',
    phonenumber: '+370 652 77894',
    email: 'sigita@mbpc.lt ',
    image: require('../assets/team/v2/Sigita.png'),
  },
  {
    full_name: 'Julija Lazutkienė',
    position: 'Administrator',
    phonenumber: '+370 699 23774',
    email: 'julija@mbpc.lt ',
    image: require('../assets/team/v2/Julija.png'),
  },
  {
    full_name: 'Tomas Aleksandravičius',
    position: 'Commercial manager',
    phonenumber: '+370 614 41057',
    email: 'tomas@mbpc.lt',
    image: require('../assets/team/v2/Tomas.png'),
  },
  {
    full_name: 'Kristina Butkevičiūtė',
    position: 'Project manager',
    phonenumber: '+370 618 46348',
    email: 'kristina@mbpc.lt',
    image: require('../assets/team/v2/Kristina.png'),
  },
]
