import React from 'react'
import styled from 'styled-components'
import PageTitle from '../components/PageTitle'
import Certificates from '../components/Certificates'
import Map from '../components/Map'

const Headline = styled.h2`
  margin-bottom: 3vh;
  margin-top: 4vh;
`

const AboutTop = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;

  @media (max-width: 991px) {
    grid-template-columns: auto;
    grid-gap: 0px;
  }
`

// const GoToProjects = styled.a`
//     background: transparent;
//     border: 0;

//     text-align: center;
//     color: white;
//     font-size: 1em;
//     padding: 0 0.2em;
//     border-radius: 7px;
//     display: inline-block;
//     text-decoration: none;

//     &:hover {
//         cursor: pointer;
//         background: rgba(0, 0, 0, 0.25);
//     }
// `;

export default () => {
  return (
    <div>
      <AboutTop>
        <div>
          <PageTitle>About us</PageTitle>
          <Headline>Responsible and professional communication</Headline>
          <p>
            JSC "MB Partners Center" is a subcontracting company for industrial projects. Our team
            is ready to provide you with skilled personnel for the offshore and onshore renewable
            energy, construction, reconstruction projects and warehouse works.
          </p>
          <ul className="colored-bullets">
            <li>Operating since 2013</li>
            {/* <li>Over 120 projects completed (<GoToProjects href="#map">Projects map</GoToProjects>)</li> */}
            <li>Over 120 projects completed</li>
            <li>Over 60 customers worldwide</li>
          </ul>
          <Headline>Health, Safety & Environment</Headline>
          <p>
            JSC "MB Partners Center" aims to have a healthy and safe workplace for all employees.
          </p>
        </div>
      </AboutTop>
      <Certificates />
      <PageTitle>Project map</PageTitle>
      <Map />
    </div>
  )
}
