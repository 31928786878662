import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: block;
  margin: 0 auto;
  padding: 1em 1.5em;
  position: relative;
  // background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0));

  ${(props) => (props.zIndex ? `z-index: ${props.zIndex}` : '')}
  ${(props) =>
    props.header ? `background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0));` : ''}
`

export default ({ zIndex = false, children }) => <Wrapper zIndex={zIndex}> {children}</Wrapper>
